import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import Layout from '../components/layout/Layout'
import getFields from '../hooks/useSingleProjectquery'
import { getItemsByLocale, getSlug } from '../utils'

export default function Project({ location, data, pageContext }) {
  const { fields } = getFields()
  const slug = getSlug(data.projectData.fields.slug)
  const currentFields = getItemsByLocale(pageContext.locale, fields.edges)
  const { areaSP, areaUnit, locationSP, projectSP, realizationSP } =
    currentFields[0].node.frontmatter.singleProject
  const project = data.projectData.frontmatter
  const openTab = true
  return (
    <Layout
      openTab={openTab}
      location={location}
      locale={pageContext.locale}
      insideProject={pageContext.insideProject}
      slug={slug}
    >
      <div className="projectInnerContainerBg">
        <div className="projectInnerContainer">
          <Carousel thumbWidth="10" showThumbs={false} infiniteLoop={true}>
            {data.gallery.frontmatter.galleryImages.map((item, i) => (
              <div key={i}>
                <GatsbyImage
                  className="projectImage"
                  image={getImage(item)}
                  alt="location"
                />
                <div className="projectContent">
                  <p className="title">{projectSP}</p>
                  <div className="titleBox">
                    <h1 className="title">{project.title}</h1>
                  </div>
                  {project.address && (
                    <div>
                      <div className="infoContainer">
                        <div className="infoBox">
                          <p className="title">{locationSP}</p>
                          <p className="info">{project.address}</p>
                        </div>
                        <div className="infoBox">
                          <p className="title">{realizationSP}</p>
                          <p className="info">{project.date}</p>
                        </div>
                        <div className="infoBox">
                          <p className="title">{areaSP}</p>
                          <p className="info">
                            {project.area} {areaUnit}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <span className="scrollLeft">GO Back</span>
                <span className="scrollRight">scroll</span>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!, $projectId: String!) {
    projectData: markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        area
        address
        date
      }
      fields {
        slug
      }
    }
    gallery: markdownRemark(id: { eq: $projectId }) {
      frontmatter {
        galleryImages {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`
