import { useStaticQuery, graphql } from 'gatsby'

const Data = () => {
  const { fields } = useStaticQuery(graphql`
    query SingleProjectQuery {
      fields: allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "buttons" } } }) {
        edges {
          node {
            frontmatter {
              singleProject {
                areaSP
                areaUnit
                locationSP
                projectSP
                realizationSP
              }
            }
            fields {
              slug
            }
          }
        }
      }
    }
  `)
  return {
    fields,
  }
}
export default Data
