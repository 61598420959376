import React from 'react'
import { Link } from 'gatsby'
import ArrowLight from '../../images/assets/line-light.svg'
import ArrowDark from '../../images/assets/line-dark.svg'
import ArrowLightShort from '../../images/assets/line-light-short.svg'
import ArrowDarkShort from '../../images/assets/line-dark-short.svg'

export const Button = props => {
  const { name, color } = props
  return (
    <button className={`button btn-${color}`}>
      <span>{name}</span>
      <div className="arrowMobile">
        {color === 'light' ? <ArrowDarkShort /> : <ArrowLightShort />}
      </div>
      <div className="arrowDesktop">{color === 'light' ? <ArrowDark /> : <ArrowLight />}</div>
    </button>
  )
}
export const LinkButton = props => {
  const { name, color, link } = props
  return (
    <Link to={link}>
      <Button name={name} color={color} />
    </Link>
  )
}
